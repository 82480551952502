.button-align {
  display: none;
}

@media only screen and (min-width: 800px) {
  .button-align {
    display: block;
  }
}

/* style.css */

.buttonNew {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  display: flex;
  font-size: 15px;
  font-weight: 500;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  border-radius: 8px;
  color: #485163;
  border: 2px solid #f5f5f5; /* Darker blue border (Google Blue) */
  background-color: #f5f5f5; /* Light gray background */
  cursor: pointer;
}
